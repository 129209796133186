import React from "react";

import { A } from "hookrouter";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="footer-left">
          <a href="/" title="" className="done-img">
            <img
              src="/img/logo-white-transparent.png"
              className="img-fluid"
              alt="Responsive image"
            />
          </a>

          <div className="text-below-done">
            <p>
              El uso de este sitio web implica la aceptación de las políticas de
              privacidad de Done.
            </p>
            <p>
              <a href="/privacy" style={{ color: "#2D9CDB" }}>
                Políticas de privacidad
              </a>
            </p>
            <p>
              Si necesitas ayuda con el soporte
              <a href="/support" style={{ color: "#2D9CDB" }}>
                {" "}
                Pincha aquí
              </a>
            </p>
          </div>
        </div>
        <div className="footer-right">
          <img
            src="/img/banco-internacional.png"
            className="img-fluid bi-img"
            alt="Responsive image"
          />
          <div className="licences">
            <div
              className="bg-blue"
              style={{ border: "0.19px solid #000000" }}
            ></div>
            <div className="bg-pink"></div>
            <div className="bg-yellow"></div>
            <div className="bg-green"></div>
          </div>
          <p>LICENCIAS</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
