import React from 'react';



class Carousel extends React.Component{

  slideData = [
    { name: 'car', text: (<p className="question">¿Tu primer <strong>auto</strong>?</p>)},
    { name: 'concert', text: (<p className="question">¿Entradas al <strong>lolla</strong>?</p>)},
    { name: 'southeast', text: (<p className="question">¿Viaje al <strong>sudeste</strong>?</p>)},
    { name: 'vacations', text: (<p className="question">¿Vacaciones al <strong>sur</strong>?</p>)},
  ]
  TICKS = 10
  constructor(props) {
    super(props);

    this.state = {
      visibility: 'presenting',
      currentSlideIndex: 0,
      visibilityTicks: this.TICKS
    }

    this.tick = this.tick.bind(this);
    this.transitionEnded = this.transitionEnded.bind(this);
  }

  tick() {
    if(this.state.visibilityTicks === 0) {
      this.setState({ visibility: 'transitioning' })
    }

    this.setState({visibilityTicks: this.state.visibilityTicks - 1});
    // else if(this.state.visibilityTicks == 0) {
    //   this.setState({ visibility: 'transitioning' });
    // } else {
    //   this.nextSlide();
    //   this.setState({ visibilityTicks: this.TICKS});
    //   return;
    // }
    
  }

  nextSlide() {
    let nextSlideIndex = this.state.currentSlideIndex + 1;
    if(nextSlideIndex >= this.slideData.length) {
      nextSlideIndex = 0;
    }
    this.setState({
      visibility: 'presenting',
      currentSlideIndex: nextSlideIndex,
      visibilityTicks: this.TICKS
    });
  }

  transitionEnded() {
    if(this.state.visibility === 'presenting') { return; }
    this.nextSlide();
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, 1000);
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }
  render() {
    const slide = this.slideData[this.state.currentSlideIndex];
    const headerClasses = `carousel ${slide.name} ${this.state.visibility}`;
    return (
    <header className={headerClasses} onTransitionEnd={this.transitionEnded}>
      {slide.text}
      <div className="get-it-done"></div>
    </header>
    )
  }
}

export default Carousel;