import React from "react";
import GoalList from "./GoalList";
import GoalDetail from "./GoalDetail";
import Dialogs from "./Dialogs";
import GoalDate from "./GoalDate";

import { useSelector, useDispatch } from "react-redux";

function GoalImage() {
  const selectedGoal = useSelector((state) => state.selectedGoal);

  return (
    <div>
      {selectedGoal !== undefined && (
        <img src={selectedGoal.image} className="selected-goal-img" />
      )}
    </div>
  );
}

export default GoalImage;
