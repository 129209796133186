import React from 'react';

import './style.scss';

function Info() {
  return (
    <main className="description">
        <div className="text">
          <h1>Si necesitas soporte puedes escribirnos al email <strong>fondosmutuos@bancointernacional.cl</strong></h1>
        </div>
        <div className="phone">
          <div className="phone-preview">
            <img src="/img/splash-android.png" className="img-fluid" alt="done application splash preview on android phone" />
          </div>
          <div className="store-links">
            <a href="https://play.google.com" >
              <img src="/img/google-play-badge.png" alt="Google Play Store Link" style={{height: '60px'}} />
            </a>
            <a href="https://appstore.apple.com">
              <img src="/img/app-store-es.svg" alt="App Store Link" />
            </a>
          </div>
        </div>
      </main>
  );
}

export default Info;