import React from 'react';
import GoalItem from './GoalItem';
import { useSelector, useDispatch } from 'react-redux';

function GoalList(props) {
  const selectedItem = useSelector(state => state.selectedItem);
  const goalData = useSelector(state => state.goalData);
  const dispatch = useDispatch();

  const handleOnItemSelected = (elName) => {
    dispatch({type: "SELECT_ITEM", payload: elName});
    props.onItemSelected(elName);
  }
    return (
      <div className="list-group">
        {goalData.map((el) => (
        <GoalItem name={el.name}
          key={el.name}
          image={el.image}
          onItemSelected={handleOnItemSelected}
          selected={selectedItem === el.name}
          params={el.params} />
        ))}
      </div>);
  
}

export default GoalList;