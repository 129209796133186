import React, { useEffect, useState, useRef } from "react";

function RedirectApp({ match }) {
  useEffect(() => {
    const isIos =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (isIos) {
      window.location.href = "done://NewGoalMenu/" + match.params.quick_code;
    }
  }, []);

  return (
    <section style={{ minHeight: "50vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        <a
          href={
            "intent:#Intent;scheme=done://NewGoalMenu/" +
            match.params.quick_code +
            ";package=cl.done.app;end"
          }
        >
          Abrir Done
        </a>
      </div>
    </section>
  );
}

export default RedirectApp;
