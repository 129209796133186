import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";

import { es } from "date-fns/esm/locale";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

function GoalDate() {
  const [selectedDate, handleDateChange] = useState();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <KeyboardDatePicker
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Date picker inline"
        value={selectedDate}
        minDate={new Date()}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default GoalDate;
