import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Axios from "axios";

function Dialogs(props) {
  const dialogOpen = useSelector((state) => state.dialogOpen);
  const dialogStep = useSelector((state) => state.dialogStep);
  const code = useSelector((state) => state.code);
  const email = useSelector((state) => state.email);
  const password = useSelector((state) => state.password);
  const dataPayload = useSelector((state) => state.dataPayload);
  const goalDialogEmailError = useSelector(
    (state) => state.goalDialogEmailError
  );
  const goalDialogPasswordError = useSelector(
    (state) => state.goalDialogPasswordError
  );
  const goalDialogCodeError = useSelector((state) => state.goalDialogCodeError);
  const resendEmailStatus = useSelector((state) => state.resendEmailStatus);
  const errorCreateUser = useSelector((state) => state.errorCreateUser);
  const errorValidateEmail = useSelector((state) => state.errorValidateEmail);

  const emailInput = useRef(email);
  const passwordInput = useRef(password);
  const codeInput = useRef(code);

  const dispatch = useDispatch();

  const nextStep = () => {
    switch (dialogStep) {
      case "step-1":
        if (validUserCreation()) {
          Axios.post(
            process.env.REACT_APP_API_URL + "landing/create",
            JSON.stringify(dataPayload),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "x-api-token": process.env.REACT_APP_API_TOKEN,
              },
            }
          )
            .then(function (response) {
              if (response.data.errors === undefined) {
                dispatch({ type: "CHANGE_DIALOG_STEP", payload: "step-2" });
              } else {
                dispatch({ type: "CHANGE_ERROR_CREATE", payload: true });
                console.log("errors: ", response.data.errors);
              }
            })
            .catch(function (error) {
              dispatch({ type: "CHANGE_ERROR_CREATE", payload: true });
              console.log(error);
            });
        }
        break;
      case "step-2":
        if (validCode(code)) {
          let data = {
            data: {
              attributes: {
                email: dataPayload.data.attributes.user.email,
                token: code,
              },
            },
          };
          Axios.post(
            process.env.REACT_APP_API_URL + "user/validate_email_token",
            JSON.stringify(data),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "x-api-token": process.env.REACT_APP_API_TOKEN,
              },
            }
          )
            .then(function (response) {
              if (response.data.errors === undefined) {
                dispatch({ type: "CHANGE_DIALOG_STEP", payload: "step-3" });
              } else {
                console.log("errors: ", response.data.errors);
                dispatch({ type: "CHANGE_ERROR_VALIDATE", payload: true });
              }
            })
            .catch(function (error) {
              dispatch({ type: "CHANGE_ERROR_VALIDATE", payload: true });
              console.log(error);
            });
        }

        break;
      case "step-3":
        dispatch({ type: "TOGGLE_DIALOG_OPEN" });
        dispatch({ type: "CLEAR_DATA" });
        var el = document.getElementById("done-description");
        el.scrollIntoView();
        // window.location.reload(true);
        break;
      default:
        break;
    }
  };

  const validUserCreation = () => {
    if (email === "" || password === "" || goalDialogEmailError) {
      return false;
    }

    return true;
  };

  const validCode = (code) => {
    if (code.length !== 8) {
      dispatch({ type: "CHANGE_DIALOG_CODE_ERROR", payload: true });
      return false;
    }

    dispatch({ type: "CHANGE_DIALOG_CODE_ERROR", payload: false });
    return true;
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    dispatch({
      type: "CHANGE_DIALOG_EMAIL_ERROR",
      payload: !re.test(String(email).toLowerCase()),
    });
  };

  const validatePassword = (password) => {
    const valid = password.length > 0;
    dispatch({ type: "CHANGE_DIALOG_PASSWORD_ERROR", payload: !valid });
  };

  const resendEmail = () => {
    let data = {
      data: {
        attributes: {
          email: dataPayload.data.attributes.user.email,
        },
      },
    };
    Axios.post(
      process.env.REACT_APP_API_URL + "user/validate_account_email",
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-api-token": process.env.REACT_APP_API_TOKEN,
        },
      }
    )
      .then(function (response) {
        if (response.data.errors === undefined) {
          dispatch({ type: "CHANGE_RESEND_SUCCESS", payload: true });
          dispatch({ type: "CHANGE_ERROR_VALIDATE", payload: false });
        } else {
          console.log("errors: ", response.data.errors);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return dialogStep === "step-1" ? (
    <Dialog
      open={dialogOpen}
      onClose={props.toggleDialogOpen}
      style={{ padding: "3em" }}
      disableBackdropClick={true}
    >
      <DialogTitle className="goal-dialog-title">
        <div className="goal-dialog-title-content">
          <img
            src="/img/logo-white-transparent.png"
            className="img-fluid done-image"
            alt="done"
          ></img>
          <span style={{ alignSelf: "left" }}>Nuevo usuario</span>
        </div>
      </DialogTitle>
      <DialogContent className="goal-dialog-content">
        <DialogContentText className="goal-dialog-content-text">
          Crea tu cuenta ingresando un correo válido y una contraseña
        </DialogContentText>
        <form className="needs-validation" noValidate>
          <div className="form-group">
            <label htmlFor="inputEmail" style={{ color: "white" }}>
              CORREO ELECTRÓNICO
            </label>
            <input
              ref={emailInput}
              onChange={() => {
                validateEmail(emailInput.current.value);
                dispatch({ type: "CHANGE_ERROR_CREATE", payload: false });
                dispatch({
                  type: "CHANGE_EMAIL",
                  payload: emailInput.current.value.toLowerCase(),
                });
              }}
              type="text"
              className="form-control goal-dialog-input"
              id={"inputEmail"}
              placeholder="done@example.com"
              required
            />
            {errorCreateUser && (
              <div className={"goal-dialog-input-text-error"}>
                Ya existe una cuenta con este correo, por favor ingresa otro.
              </div>
            )}
            <div
              className={
                "goal-dialog-input-text" +
                (goalDialogEmailError ? "-error" : "-correct")
              }
            >
              Por favor ingrese un correo válido.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="inputPassword" style={{ color: "white" }}>
              CONTRASEÑA
            </label>
            <input
              ref={passwordInput}
              onChange={() => {
                validatePassword(passwordInput.current.value);
                dispatch({
                  type: "CHANGE_PASSWORD",
                  payload: passwordInput.current.value,
                });
              }}
              type="password"
              className="form-control goal-dialog-input"
              id={"inputPassword"}
            />
            <div
              className={
                "goal-dialog-input-text" +
                (goalDialogPasswordError ? "-error" : "-correct")
              }
            >
              Por favor ingrese una contraseña.
            </div>
          </div>
        </form>
        <div className="text-field">
          <Button
            className="goal-dialog-next"
            onClick={() => nextStep()}
            color="primary"
            variant="outlined"
          >
            Siguiente
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  ) : dialogStep === "step-2" ? (
    <Dialog
      open={dialogOpen}
      onClose={props.toggleDialogOpen}
      style={{ padding: "3em" }}
    >
      <DialogTitle className="goal-dialog-title">
        <div className="goal-dialog-title-content">
          <img
            src="/img/logo-white-transparent.png"
            className="img-fluid done-image"
            alt="done"
          ></img>
          <span style={{ alignSelf: "left" }}>Verificación</span>
        </div>
      </DialogTitle>
      <DialogContent className="goal-dialog-content">
        <DialogContentText className="goal-dialog-content-text">
          Ingresa el código enviado a {email} para validar tu correo
        </DialogContentText>
        <div className="text-field">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" style={{ color: "white" }}>
              CÓDIGO
            </label>
            <input
              ref={codeInput}
              value={code.toUpperCase()}
              onChange={() => {
                validCode(codeInput.current.value);
                dispatch({ type: "CHANGE_ERROR_VALIDATE", payload: false });
                dispatch({
                  type: "CHANGE_CODE",
                  payload: codeInput.current.value.toUpperCase(),
                });
              }}
              type="text"
              className="form-control goal-dialog-input"
              placeholder="Ej: 0DDZ45"
            />
            {resendEmailStatus && (
              <div className={"goal-dialog-resend-email-correct"}>
                Se ha enviado exitosamente el correo.
              </div>
            )}
            {errorValidateEmail && (
              <div className={"goal-dialog-input-text-error"}>
                El código ingresado es incorrecto, por favor vuelve a intentar o
                solicita uno nuevo presionando en "Reenviar correo".
              </div>
            )}
            <div
              className={
                "goal-dialog-input-text" +
                (goalDialogCodeError ? "-error" : "-correct")
              }
            >
              El código ingresado no es válido.
            </div>
          </div>
          <Button
            onClick={() => resendEmail()}
            className="MuiButton-outlinedPrimary-white"
          >
            Reenviar correo
          </Button>
          <Button
            onClick={() => nextStep()}
            className="MuiButton-outlinedPrimary"
          >
            Siguiente
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog
      open={dialogOpen}
      onClose={props.toggleDialogOpen}
      style={{ padding: "3em" }}
    >
      <DialogTitle className="goal-dialog-title">
        <div className="goal-dialog-title-content">
          <img
            src="/img/logo-white-transparent.png"
            className="img-fluid done-image"
            alt="done"
          ></img>
          <span style={{ alignSelf: "left" }}>Cuenta creada con éxito</span>
        </div>
      </DialogTitle>
      <DialogContent className="goal-dialog-content">
        <DialogContentText className="goal-dialog-content-text">
          Estás listo para utilizar Done, ahora debes descargar la app, ingresar
          tus datos personales con nuestro asistente de configuración y comenzar
          a invertir
        </DialogContentText>
        <div className="text-field">
          <Button
            onClick={() => nextStep()}
            style={{ marginTop: "10em" }}
            color="primary"
            variant="outlined"
          >
            ¡Vamos allá!
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Dialogs;
