import React from 'react';

import './style.scss';
import Carousel from './Carousel';
import Description from './Description';
import GoalSimulator from './GoalSimulator';
import Footer from './Footer';


export default function Landing() {
  return (
    <>
      <Carousel />
      <Description />
      <GoalSimulator />
    </>
  )
}
