import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./style.scss";
import Landing from "./Landing";
import Footer from "./Footer";
import Privacy from "./Privacy";
import Support from "./Support";
import RedirectApp from "./RedirectApp";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/privacy" component={Privacy} />
          <Route exact path="/" component={Landing} />
          <Route path="/support" component={Support} />
          <Route path="/apple-app-site-association" />
          <Route
            path="/redirect-app-done/:quick_code"
            component={RedirectApp}
          />
        </Switch>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
