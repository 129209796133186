import React from "react";
import GoalList from "./GoalList";
import GoalDetail from "./GoalDetail";
import Dialogs from "./Dialogs";
import GoalDate from "./GoalDate";
import GoalImage from "./GoalImage";

import { useSelector, useDispatch } from "react-redux";

function GoalSimulator() {
  const goalData = useSelector((state) => state.goalData);
  const selectedGoal = useSelector((state) => state.selectedGoal);
  const extension = useSelector((state) => state.extension);
  const name = useSelector((state) => state.name);
  const stage = useSelector((state) => state.stage);
  const transportValue = useSelector((state) => state.transportValue);
  const lodgingValue = useSelector((state) => state.lodgingValue);
  const feedingValue = useSelector((state) => state.feedingValue);
  const othersValue = useSelector((state) => state.othersValue);
  const total = useSelector((state) => state.total);
  const email = useSelector((state) => state.email);
  const password = useSelector((state) => state.password);
  const date = useSelector((state) => state.date);

  const dispatch = useDispatch();

  const handleOnItemSelected = (name) => {
    let chosenGoal = goalData.filter((goal) => goal.name === name)[0];
    dispatch({ type: "CLEAR_DATA" });
    dispatch({ type: "CHANGE_STAGE", payload: "step-2" });
    dispatch({ type: "SELECT_ITEM", payload: { selectedItem: name } });
    dispatch({ type: "SELECT_GOAL", payload: chosenGoal });
  };

  const toggleDialogOpen = () => {
    dispatch({ type: "TOGGLE_DIALOG_OPEN" });
  };

  const onClickCreate = () => {
    const payload = {
      data: {
        attributes: {
          user: {
            email: email,
            password: password,
          },
          goal: {
            name: name,
            category_slug: selectedGoal.categorySlug,
            sub_category_slug: selectedGoal.categorySlug,
            due_at: date,
            parent_ref: null,
            split: 30,
            total_cost: total.toString(),
            extension: extension,
            costs: [
              { dimension: "TRANSPORTE", value: transportValue },
              { dimension: "ALOJAMIENTO", value: lodgingValue },
              { dimension: "ALIMENTACION", value: feedingValue },
              { dimension: "OTROS", value: othersValue },
            ],
          },
        },
      },
    };
    dispatch({ type: "CHANGE_PAYLOAD", payload: payload });
    toggleDialogOpen();
  };

  const isValid = () => {
    if (
      name !== "" &&
      date !== undefined &&
      transportValue + lodgingValue + feedingValue + othersValue > 0
    ) {
      return true;
    }
    return false;
  };

  const format = (value) => {
    var num = value.replace(/\./g, "");
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^[.]/, "");
      return num;
    } else {
      return num.replace(/[^\d.]*/g, "");
    }
  };

  let className = `goal-simulator container-fluid ${stage}`;
  const costComponents =
    selectedGoal === undefined ? [] : selectedGoal.params.costComponents;
  const extensionText =
    selectedGoal === undefined ? [] : selectedGoal.params.extensionText;

  return (
    <div>
      <Dialogs toggleDialogOpen={() => toggleDialogOpen()} />
      <section className={className}>
        <div className="firstColumn">
          <div className="step firstStep">
            <h3>Elige tu meta</h3>
            <GoalList onItemSelected={handleOnItemSelected} />
            <GoalImage />
          </div>
        </div>
        <div className="secondColumn">
          <div className="step secondStep">
            <GoalDetail
              costComponents={costComponents}
              extensionText={extensionText}
            />
          </div>
          <div className="step thirdStep">
            <h3>Fecha de meta</h3>
            <GoalDate />
            <div className="goal-total">
              <h3>Total: </h3>
              <h2>
                <strong>${format(total.toString())}</strong>
              </h2>
            </div>
            <div
              className={isValid() ? "button-next-3" : "button-next-3-disable"}
            >
              <button
                type="button"
                disabled={!isValid()}
                className="btn bg-primary btn-block btn-init"
                onClick={() => onClickCreate()}
              >
                {" "}
                {"Iniciar meta"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GoalSimulator;
