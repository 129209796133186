import React from 'react';


function GoalItem(props) {
  const handleOnClick = () => {
    props.onItemSelected(props.name);
  }
  const classes = `list-group-item list-group-item-action ${props.selected ? "active" : ""}`
    
  return (
    <a className={classes} onClick={handleOnClick} href={`#${props.name}`}>
      { props.name }
    </a>
  )
}

export default GoalItem;