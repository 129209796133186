import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "@material-ui/core/Slider";
import Add from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

//import Icon from '@material-ui/core/Icon';

function GoalDetail(props) {
  const extension = useSelector((state) => state.extension);
  const name = useSelector((state) => state.name);
  const selectedGoal = useSelector((state) => state.selectedGoal);
  const stage = useSelector((state) => state.stage);
  const transportValue = useSelector((state) => state.transportValue);
  const lodgingValue = useSelector((state) => state.lodgingValue);
  const feedingValue = useSelector((state) => state.feedingValue);
  const othersValue = useSelector((state) => state.othersValue);
  const extensionInput = useRef(extension);
  const nameInput = useRef(name);

  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();

  const toggleRender = () => {
    setEditing(!editing);
  };

  const saveExtension = () => {
    if (extensionInput.current.value.length > 8) {
      extensionInput.current.value = extensionInput.current.value.substring(
        0,
        8
      );
    } else {
      dispatch({
        type: "CHANGE_EXTENSION",
        payload: parseInt(extensionInput.current.value),
      });
      setEditing(false);
    }
  };

  const renderExtension = () => {
    return (
      <span className="display-4" onClick={() => toggleRender()}>
        {format(extension.toString())}
      </span>
    );
  };
  const renderExtensionEdit = () => {
    return (
      <div className="edit-container" style={{ marginBottom: "10px" }}>
        <input
          className="form-control from_class"
          ref={extensionInput}
          min="0"
          max="99999999"
          maxLength={12}
          type="number"
          defaultValue={extension}
        />
        <div class="invalid-feedback">Please choose a username.</div>
        <button
          className="btn btn-success btn-sm"
          type="button"
          onClick={() => saveExtension()}
        >
          OK!
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (name !== undefined && extension !== 0 && stage === "step-2") {
      dispatch({ type: "CHANGE_STAGE", payload: "step-3" });
    }
  });

  const format = (value) => {
    var num = value.replace(/\./g, "");
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^[\.]/, "");
      return num;
    } else {
      return num.replace(/[^\d\.]*/g, "");
    }
  };

  return selectedGoal === undefined ? (
    <div></div>
  ) : (
    <div className="goal-details">
      <div className="form-group">
        <h3 htmlFor="goal-name">Nombra tu meta</h3>
        <input
          type="text"
          id="goal-name"
          maxLength={80}
          ref={nameInput}
          onChange={() =>
            dispatch({ type: "CHANGE_NAME", payload: nameInput.current.value })
          }
          className="form-control"
        />
      </div>
      <div className="extensionContainer">
        <h4>Extensión</h4>
        <div className="card shadow">
          <div className="card-body">
            {editing ? (
              renderExtensionEdit()
            ) : (
              <div className="row">
                <div
                  className="col-3 text-center"
                  style={{ alignSelf: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => dispatch({ type: "DECREMENT" })}
                  >
                    <RemoveIcon className="remove-icon" />
                  </button>
                </div>
                <div className="col-6 text-center">
                  {renderExtension()}
                  <p className="border border-secondary border-left-0 border-right-0 border-bottom-0">
                    {props.extensionText}
                  </p>
                </div>
                <div
                  className="col-3 text-center"
                  style={{ alignSelf: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => dispatch({ type: "INCREMENT" })}
                  >
                    <Add className="add-icon" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedGoal.params.costComponents.map((cost) => (
        <div key={cost.key} className="sliderContainer">
          <h4 htmlFor={cost.key + "-slider"}>{cost.name}</h4>
          <Slider
            defaultValue={0}
            step={5000}
            value={
              cost.key === "transport"
                ? transportValue
                : cost.key === "lodging"
                ? lodgingValue
                : cost.key === "feeding"
                ? feedingValue
                : othersValue
            }
            valueLabelFormat={
              "$" +
              (cost.key === "transport"
                ? format(transportValue.toString())
                : cost.key === "lodging"
                ? format(lodgingValue.toString())
                : cost.key === "feeding"
                ? format(feedingValue.toString())
                : format(othersValue.toString()))
            }
            min={cost.min}
            max={cost.max}
            valueLabelDisplay="on"
            aria-labelledby={cost.key + "-slider"}
            onChange={(event, value) => {
              switch (cost.key) {
                case "transport":
                  dispatch({ type: "CHANGE_TRANSPORT_VALUE", payload: value });
                  break;
                case "lodging":
                  dispatch({ type: "CHANGE_LODGING_VALUE", payload: value });
                  break;
                case "feeding":
                  dispatch({ type: "CHANGE_FEEDING_VALUE", payload: value });
                  break;
                case "others":
                  dispatch({ type: "CHANGE_OTHERS_VALUE", payload: value });
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default GoalDetail;
