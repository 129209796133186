import React from 'react';

import Info from './Info';

import './style.scss';

function Support() {
  return (
    <div className="App">
      <Info />
    </div>
  );
}

export default Support;