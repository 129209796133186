import React from "react";

class Description extends React.Component {
  render() {
    return (
      <main className="description" id="done-description">
        <div className="text">
          <h1>
            La app de ahorro que te ayuda a completar tus metas personales
          </h1>
        </div>
        <div className="phone">
          <div className="phone-preview">
            <img
              src="/img/splash-android.png"
              className="img-fluid"
              alt="done application splash preview on android phone"
            />
          </div>
          <div className="store-links">
            <a href="https://play.google.com/store/apps/details?id=cl.done.app&hl=es_CL">
              <img
                src="/img/google-play-badge.png"
                alt="Google Play Store Link"
                style={{ height: "60px" }}
              />
            </a>
            <a href="https://apps.apple.com/cl/app/done/id1482582067?l=en">
              <img src="/img/app-store-es.svg" alt="App Store Link" />
            </a>
          </div>
        </div>
      </main>
    );
  }
}

export default Description;
