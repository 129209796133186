import React from 'react';

import './style.scss';



export default function Privacy() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">


          <article>
            <h1 className="code-line" data-line-start="100" data-line-end="1"><a id="Poltica_de_privacidad_0"></a>Política de privacidad</h1>
            <p className="has-line-data" data-line-start="2" data-line-end="3">Banco Internacional Administradora General de Fondos
            S.A te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter
            personal de los usuarios y clientes que puedan ser recabados por la navegación o contratación de servicios a
      través de la aplicación Done.</p>
            <p className="has-line-data" data-line-start="4" data-line-end="5">En este sentido, el Titular garantiza el cumplimiento
            de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de
            diciembre, de Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el
            Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de
      las personas físicas (RGPD).</p>
            <p className="has-line-data" data-line-start="6" data-line-end="7">El uso de la plataforma web y aplicación implica la
      aceptación de esta Política de Privacidad así como las condiciones incluidas en el Aviso Legal.</p>
            <h2 className="code-line" data-line-start="8" data-line-end="9" > <a id="Identidad_del_responsable_8"></a>Identidad del
  responsable</h2 >
            <ul>
              <li className="has-line-data" data-line-start="10" data-line-end="11">Titular: Banco Internacional Administradora
        General de Fondos S.A</li>
              <li className="has-line-data" data-line-start="11" data-line-end="12">RUT: 76.930.984-5</li>
              <li className="has-line-data" data-line-start="12" data-line-end="13">Domicilio: Apoquindo 6750</li>
              <li className="has-line-data" data-line-start="13" data-line-end="14">Correo electrónico: <a
                href="mailto:fondosmutuos@bancointernacional.cl">fondosmutuos@bancointernacional.cl</a></li>
              <li className="has-line-data" data-line-start="14" data-line-end="16">Sitio Web: <a
                href="https://www.bancointernacional.cl/">https://www.bancointernacional.cl/</a></li>
            </ul>
            <h2 className="code-line" data-line-start="16" data-line-end="17"> <a
              id="Principios_aplicados_en_el_tratamiento_de_datos_16"></a>Principios aplicados en el tratamiento de datos</h2 >
            <p className="has-line-data" data-line-start="18" data-line-end="19">En el tratamiento de tus datos personales, el
            Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de
      protección de datos:</p>
            <ul>
              <li className="has-line-data" data-line-start="20" data-line-end="22">
                <p className="has-line-data" data-line-start="20" data-line-end="21">Principio de licitud, lealtad y transparencia:
                El Titular siempre requerirá el consentimiento para el tratamiento de tus datos personales que puede ser para
          uno o varios fines específicos sobre los que te informará previamente con absoluta transparencia.</p>
              </li>
              <li className="has-line-data" data-line-start="22" data-line-end="24">
                <p className="has-line-data" data-line-start="22" data-line-end="23">Principio de minimización de datos: El Titular
          te solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita.</p>
              </li>
              <li className="has-line-data" data-line-start="24" data-line-end="27">
                <p className="has-line-data" data-line-start="24" data-line-end="26">
                  Principio de limitación del plazo de conservación: Los datos se mantendrán durante el tiempo estrictamente necesario para el fin o los fines del tratamiento.<br />
            El Titular te informará del plazo de conservación correspondiente según la finalidad. En el caso de suscripciones, el Titular revisará periódicamente las listas y eliminará aquellos registros inactivos durante un tiempo considerable.
          </p>
              </li>
              <li className="has-line-data" data-line-start="27" data-line-end="29">
                <p className="has-line-data" data-line-start="27" data-line-end="28">Principio de integridad y confidencialidad: Tus
                datos serán tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada. Debes
                saber que el Titular toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de
          los datos de sus usuarios por parte de terceros.</p>
              </li>
            </ul>
            <h2 className="code-line" data-line-start="29" data-line-end="30"><a id="Obtencin_de_datos_personales_29"></a>Obtención de
      datos personales</h2>
            <p className="has-line-data" data-line-start="31" data-line-end="32">Para navegar por <a
              href="http://www.done.cl">http://www.done.cl</a> no es necesario que facilites ningún dato personal. Los casos
      en los que sí proporcionas tus datos personales dentro de la página web y la aplicación móvil son los siguientes:
    </p>
            <ul>
              <li className="has-line-data" data-line-start="33" data-line-end="34">Al inscribirte como usuario de la aplicación a
        través de un formulario de suscripción.</li>
              <li className="has-line-data" data-line-start="34" data-line-end="35">Al contactar a través de los formularios de
        contacto o enviar un correo electrónico.</li>
              <li className="has-line-data" data-line-start="35" data-line-end="37">Al momento de responder la encuesta de riesgo.
      </li>
            </ul>
            <h2 className="code-line" data-line-start="37" data-line-end="38"> <a id="Tus_derechos_37"></a>Tus derechos</h2 >
            <p className="has-line-data" data-line-start="39" data-line-end="40">El Titular te informa que sobre tus datos
      personales tienes derecho a:</p>
            <ul>
              <li className="has-line-data" data-line-start="41" data-line-end="42">Solicitar el acceso a los datos almacenados.
      </li>
              <li className="has-line-data" data-line-start="42" data-line-end="43">Solicitar una rectificación o la cancelación.
      </li>
              <li className="has-line-data" data-line-start="43" data-line-end="44">Solicitar la limitación de su tratamiento.</li>
              <li className="has-line-data" data-line-start="44" data-line-end="45">Oponerte al tratamiento.</li>
              <li className="has-line-data" data-line-start="45" data-line-end="47">Solicitar la portabilidad de tus datos.</li>
            </ul>
            <p className="has-line-data" data-line-start="47" data-line-end="48">El ejercicio de estos derechos es personal y por
            tanto debe ser ejercido directamente por el interesado, solicitándolo directamente al Titular, lo que significa
            que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento puede dirigirse al
            Titular y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido, solicitar la
            rectificación de los mismos, solicitar la portabilidad de sus datos personales, oponerse al tratamiento, limitar
      su uso o solicitar la cancelación de esos datos en los ficheros del Titular.</p>
            <p className="has-line-data" data-line-start="49" data-line-end="50">Para ejercitar tus derechos de acceso,
            rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico junto con la prueba
      válida en derecho como una fotocopia del carné de identidad o equivalente.</p>
            <p className="has-line-data" data-line-start="51" data-line-end="52">Tienes derecho a la tutela judicial efectiva y a
            presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos,
      si consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.</p>
            <h2 className="code-line" data-line-start="53" data-line-end="54"> <a
              id="Finalidad_del_tratamiento_de_datos_personales_53"></a>Finalidad del tratamiento de datos personales</h2 >
            <p className="has-line-data" data-line-start="55" data-line-end="56">Cuando te conectas a la aplicación para mandar un
            correo al Titular o realizas una contratación, estás facilitando información de carácter personal de la que el
            responsable es el Titular. Esta información puede incluir datos de carácter personal como pueden ser tu dirección
            IP, nombre y apellidos, rut, ciudad, dirección de correo electrónico, y otra información. Al facilitar esta
            información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por
      <a href="http://done.cl">done.cl</a> , sólo como se describe en el Aviso Legal y en la presente Política de
      Privacidad.</p>
            <p className="has-line-data" data-line-start="57" data-line-end="58">Los datos personales y la finalidad del tratamiento
      por parte del Titular es diferente según el sistema de captura de información:</p>
            <ul>
              <li className="has-line-data" data-line-start="59" data-line-end="61">
                <p className="has-line-data" data-line-start="59" data-line-end="60">Formularios de suscripción a la aplicación: El
                Titular solicita los siguientes datos personales: Nombre y apellidos, documento de identificación, ciudad y
                los siguientes datos bancarios: nombre del titular, número de identificación nacional, institución bancaria,
                tipo de cuenta y número de cuenta. Los datos que facilites al Titular estarán ubicados en los servidores de
          Heroku, con domicilio en Chile.</p>
              </li>
              <li className="has-line-data" data-line-start="61" data-line-end="63">
                <p className="has-line-data" data-line-start="61" data-line-end="62">Encuesta de riesgo: El Titular solicita datos
          de preferencia con la finalidad de evaluar el perfil del cliente.</p>
              </li>
            </ul>
            <p className="has-line-data" data-line-start="63" data-line-end="64">Existen otras finalidades por las que el Titular
      trata tus datos personales:</p>
            <ul>
              <li className="has-line-data" data-line-start="65" data-line-end="67">
                <p className="has-line-data" data-line-start="65" data-line-end="66">Para garantizar el cumplimiento de las
                condiciones recogidas en el Aviso Legal y en la ley aplicable. Esto puede incluir el desarrollo de
                herramientas y algoritmos que ayuden a esta aplicación a garantizar la confidencialidad de los datos
          personales que recoge.</p>
              </li>
              <li className="has-line-data" data-line-start="67" data-line-end="69">
                <p className="has-line-data" data-line-start="67" data-line-end="68">Para apoyar y mejorar los servicios que ofrece
          esta aplicación</p>
              </li>
              <li className="has-line-data" data-line-start="69" data-line-end="71">
                <p className="has-line-data" data-line-start="69" data-line-end="70">Para analizar la navegación. El Titular recoge
                otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en tu ordenador
                cuando navegas por el sitio Web cuyas características y finalidad están detalladas en la Política de Cookies .
        </p>
              </li>
            </ul>
            <h2 className="code-line" data-line-start="71" data-line-end="72"> <a id="Seguridad_de_los_datos_personales_71"></a>Seguridad
  de los datos personales</h2 >
            <p className="has-line-data" data-line-start="73" data-line-end="74">Para proteger tus datos personales, el Titular toma
            todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso,
      acceso indebido, divulgación, alteración o destrucción de los mismos.</p>
            <p className="has-line-data" data-line-start="75" data-line-end="76">El sitio Web está alojado en Netlifly. La seguridad
            de tus datos está garantizada, ya que toman todas las medidas de seguridad necesarias para ello. Puedes consultar
      su política de privacidad para tener más información.</p>
            <h2 className="code-line" data-line-start="77" data-line-end="78"> <a id="Poltica_de_Cookies_77"></a>Política de Cookies</h2 >
            <p className="has-line-data" data-line-start="79" data-line-end="80">Para que este sitio Web funcione correctamente
      necesita utilizar cookies, que es una información que se almacena en tu navegador web.</p>
            <p className="has-line-data" data-line-start="81" data-line-end="82">En la página Política de Cookies puedes consultar
      toda la información relativa a la política de recogida, la finalidad y el tratamiento de las cookies.</p>
            <h2 className="code-line" data-line-start="83" data-line-end="84"> <a
              id="Legitimacin_para_el_tratamiento_de_datos_83"></a>Legitimación para el tratamiento de datos</h2 >
            <p className="has-line-data" data-line-start="85" data-line-end="86">La base legal para el tratamiento de tus datos es:
      el consentimiento.</p>
            <p className="has-line-data" data-line-start="87" data-line-end="88">Para contactar con el Titular, suscribirte a la
      aplicación o realizar comentarios en la aplicación tienes que aceptar la presente Política de Privacidad.</p>
            <h2 className="code-line" data-line-start="89" data-line-end="90"> <a id="Categoras_de_datos_personales_89"></a>Categorías de
  datos personales</h2 >
            <p className="has-line-data" data-line-start="91" data-line-end="92">Las categorías de datos personales que trata el
      Titular son:</p>
            <ul>
              <li className="has-line-data" data-line-start="93" data-line-end="94">Datos identificativos.</li>
              <li className="has-line-data" data-line-start="94" data-line-end="95">Datos bancarios.</li>
              <li className="has-line-data" data-line-start="95" data-line-end="97">Datos sobre preferencias.</li>
            </ul>
            <h2 className="code-line" data-line-start="97" data-line-end="98"> <a
              id="Conservacin_de_datos_personales_97"></a>Conservación de datos personales</h2 >
            <p className="has-line-data" data-line-start="99" data-line-end="100">Los datos personales que proporciones al Titular
      se conservarán hasta que solicites su supresión.</p>
            <h2 className="code-line" data-line-start="101" data-line-end="102"> <a id="Navegacin_Web_101"></a>Navegación Web</h2 >
            <p className="has-line-data" data-line-start="103" data-line-end="104">Al navegar por <a
              href="http://www.done.cl">http://www.done.cl</a> o la aplicación móvil se pueden recoger datos no
      identificativos, que pueden incluir, la dirección IP, geolocalización, un registro de cómo se utilizan los
      servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte.</p>
            <h2 className="code-line" data-line-start="105" data-line-end="106"> <a
              id="Exactitud_y_veracidad_de_los_datos_personales_105"></a>Exactitud y veracidad de los datos personales</h2 >
            <p className="has-line-data" data-line-start="107" data-line-end="108">Te comprometes a que los datos facilitados al
      Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.</p>
            <p className="has-line-data" data-line-start="109" data-line-end="110">Como Usuario de la aplicación y el sitio Web eres
            el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando a el Titular de
      cualquier responsabilidad al respecto.</p>
            <h2 className="code-line" data-line-start="111" data-line-end="112"> <a id="Aceptacin_y_consentimiento_111"></a>Aceptación y
  consentimiento</h2 >
            <p className="has-line-data" data-line-start="113" data-line-end="114">Como Usuario del sitio Web declaras haber sido
            informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento
            de los mismos por parte de el Titular en la forma y para las finalidades indicadas en esta Política de Privacidad.
    </p>
            <h2 className="code-line" data-line-start="115" data-line-end="116"> <a id="Revocabilidad_115"></a>Revocabilidad</h2 >
            <p className="has-line-data" data-line-start="117" data-line-end="118">Para ejercitar tus derechos de acceso,
            rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico junto con la prueba
      válida en derecho como una fotocopia del carné de identidad o equivalente.</p>
            <p className="has-line-data" data-line-start="119" data-line-end="120">El ejercicio de tus derechos no incluye ningún
      dato que el Titular esté obligado a conservar con fines administrativos, legales o de seguridad.</p>
            <h2 className="code-line" data-line-start="121" data-line-end="122"> <a
              id="Cambios_en_la_Poltica_de_Privacidad_121"></a>Cambios en la Política de Privacidad</h2 >
            <p className="has-line-data" data-line-start="123" data-line-end="124">El Titular se reserva el derecho a modificar la
            presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas
      de la industria.</p>
            <p className="has-line-data" data-line-start="125" data-line-end="126">Estas políticas estarán vigentes hasta que sean
      modificadas por otras debidamente publicadas.</p>
          </article >
        </div>
      </div>
    </div>
  )
}