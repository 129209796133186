const initialState = {
  selectedItem: "",
  selectedGoal: undefined,
  stage: "step-1",
  extension: 0,
  name: "",
  date: Date(),
  dialogOpen: false,
  dialogStep: "step-1",
  transportValue: 0,
  lodgingValue: 0,
  feedingValue: 0,
  othersValue: 0,
  email: "",
  password: "",
  code: "",
  total: 0,
  goalDialogEmailError: false,
  goalDialogPasswordError: false,
  goalDialogCodeError: false,
  dataPayload: undefined,
  resendEmailStatus: false,
  errorCreateUser: false,
  errorValidateEmail: false,
  goalData: [
    {
      name: "Viaje",
      categorySlug: "TRAVEL",
      image: "/img/vacacionesdone.png",
      params: {
        costComponents: [
          {
            key: "transport",
            name: "Transporte",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "lodging",
            name: "Alojamiento",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "feeding",
            name: "Alimentación",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "others",
            name: "Otros",
            min: 0,
            max: 1_000_000,
          },
        ],
        extensionText: "Días",
      },
    },
    {
      name: "Estudio",
      categorySlug: "STUDY",
      image: "/img/studies.png",
      params: {
        costComponents: [
          {
            key: "transport",
            name: "Transporte",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "lodging",
            name: "Alojamiento",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "feeding",
            name: "Alimentación",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "others",
            name: "Otros",
            min: 0,
            max: 1_000_000,
          },
        ],
        extensionText: "Semestres",
      },
    },
    {
      name: "Depto",
      categorySlug: "APARTMENT",
      image: "/img/apartments.png",
      params: {
        costComponents: [
          {
            key: "transport",
            name: "Transporte",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "lodging",
            name: "Alojamiento",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "feeding",
            name: "Alimentación",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "others",
            name: "Otros",
            min: 0,
            max: 1_000_000,
          },
        ],
        extensionText: "Metros cuadrados",
      },
    },
    {
      name: "Otro",
      categorySlug: "OTHER",
      image: "/img/others.png",
      params: {
        costComponents: [
          {
            key: "transport",
            name: "Transporte",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "lodging",
            name: "Alojamiento",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "feeding",
            name: "Alimentación",
            min: 0,
            max: 1_000_000,
          },
          {
            key: "others",
            name: "Otros",
            min: 0,
            max: 1_000_000,
          },
        ],
        extensionText: "Pesos",
      },
    },
  ],
};

function goalReducer(state = initialState, action) {
  switch (action.type) {
    case "CLEAR_DATA":
      return {
        ...state,
        selectedItem: "",
        selectedGoal: undefined,
        stage: "step-1",
        extension: 0,
        name: "",
        date: Date(),
        dialogOpen: false,
        dialogStep: "step-1",
        transportValue: 0,
        lodgingValue: 0,
        feedingValue: 0,
        othersValue: 0,
        resendEmailStatus: false,
      };
    case "FETCH_GOAL_DATA":
      return { ...state, goals: action.payload };
    case "SELECT_ITEM":
      return { ...state, selectedItem: action.payload.selectedItem };
    case "CHANGE_STAGE":
      return { ...state, stage: action.payload };
    case "SELECT_GOAL":
      return { ...state, selectedGoal: action.payload };
    case "INCREMENT":
      return {
        ...state,
        extension:
          parseInt(state.extension.toString().replace(/\./g, ""), 10) + 1,
      };
    case "DECREMENT":
      return {
        ...state,
        extension:
          state.extension - 1 < 0
            ? 0
            : parseInt(state.extension.toString().replace(/\./g, ""), 10) - 1,
      };
    case "CHANGE_EXTENSION":
      return { ...state, extension: action.payload };
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
    case "CHANGE_DATE":
      return { ...state, date: action.payload };
    case "TOGGLE_DIALOG_OPEN":
      return { ...state, dialogOpen: !state.dialogOpen };
    case "CHANGE_DIALOG_STEP":
      return { ...state, dialogStep: action.payload };
    case "CHANGE_TRANSPORT_VALUE":
      return {
        ...state,
        transportValue: action.payload,
        total:
          action.payload +
          state.lodgingValue +
          state.feedingValue +
          state.othersValue,
      };
    case "CHANGE_LODGING_VALUE":
      return {
        ...state,
        lodgingValue: action.payload,
        total:
          state.transportValue +
          action.payload +
          state.feedingValue +
          state.othersValue,
      };
    case "CHANGE_FEEDING_VALUE":
      return {
        ...state,
        feedingValue: action.payload,
        total:
          state.transportValue +
          state.lodgingValue +
          action.payload +
          state.othersValue,
      };
    case "CHANGE_OTHERS_VALUE":
      return {
        ...state,
        othersValue: action.payload,
        total:
          state.transportValue +
          state.lodgingValue +
          state.feedingValue +
          action.payload,
      };
    case "CHANGE_EMAIL":
      return {
        ...state,
        dataPayload: {
          ...state.dataPayload,
          data: {
            ...state.data,
            attributes: {
              ...state.attributes,
              user: {
                ...state.user,
                email: action.payload,
                password: state.dataPayload.data.attributes.user.password,
              },
              goal: { ...state.dataPayload.data.attributes.goal },
            },
          },
        },
        email: action.payload,
      };
    case "CHANGE_PASSWORD":
      return {
        ...state,
        dataPayload: {
          ...state.dataPayload,
          data: {
            ...state.data,
            attributes: {
              ...state.attributes,
              user: {
                ...state.user,
                email: state.dataPayload.data.attributes.user.email,
                password: action.payload,
              },
              goal: { ...state.dataPayload.data.attributes.goal },
            },
          },
        },
        password: action.payload,
      };
    case "CHANGE_PAYLOAD":
      return { ...state, dataPayload: action.payload };
    case "CHANGE_CODE":
      return { ...state, code: action.payload };
    case "CHANGE_DIALOG_EMAIL_ERROR":
      return { ...state, goalDialogEmailError: action.payload };
    case "CHANGE_DIALOG_PASSWORD_ERROR":
      return { ...state, goalDialogPasswordError: action.payload };
    case "CHANGE_DIALOG_CODE_ERROR":
      return {
        ...state,
        goalDialogCodeError: action.payload,
        resendEmailStatus: false,
      };
    case "CHANGE_RESEND_SUCCESS":
      return { ...state, resendEmailStatus: action.payload };
    case "CHANGE_ERROR_CREATE":
      return {
        ...state,
        errorCreateUser: action.payload,
      };
    case "CHANGE_ERROR_VALIDATE":
      return {
        ...state,
        errorValidateEmail: action.payload,
      };
    default:
      return state;
  }
}

export default goalReducer;
